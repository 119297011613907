import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import i18n from '../../i18n';
import { LocalizationContext } from '../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';

export default function PatientsSurvey(props) {
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    const { t } = useTranslation("PatientsSurvey");
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [patientName, setPatientName] = useState('');
    const [date, setDate] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [timing, setTiming] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [question6, setQuestion6] = useState('');
    const [question7, setQuestion7] = useState('');
    const [question8, setQuestion8] = useState('');
    const [question9, setQuestion9] = useState('');
    const [question10, setQuestion10] = useState('');
    const [total, setTotal] = useState(0);
    const [errors, setErrors] = useState([]);

    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    const validateForm = () => {
        const errors = [];


        if (patientName.trim() === '') {
            errors.push(t('Please provide Your Name.'));
        }
        if (age.trim() === '') {
            errors.push(t('Please provide Your Age.'));
        }

        if (gender.trim() === '') {
            errors.push(t('Please provide Your Gender.'));
        }
        if (timing.trim() === '') {
            errors.push(t('Please provide Your Timing'));
        }
        if (timing === t('After Surgery')) {
            if (date.trim() === '') {
                errors.push(t('Please provide The Date.'));
            }
        }


        if (question1.trim() === '') {
            errors.push(t('Please provide a response for Question 1.'));
        }
        if (question2.trim() === '') {
            errors.push(t('Please provide a response for Question 2.'));
        }

        if (question3.trim() === '') {
            errors.push(t('Please provide a response for Question 3.'));
        }

        if (question4.trim() === '') {
            errors.push(t('Please provide a response for Question 4.'));
        }

        if (question5.trim() === '') {
            errors.push(t('Please provide a response for Question 5.'));
        }
        if (question6.trim() === '') {
            errors.push(t('Please provide a response for Question 6.'));
        }
        if (question7.trim() === '') {
            errors.push(t('Please provide a response for Question 7.'));
        }

        if (question8.trim() === '') {
            errors.push(t('Please provide a response for Question 8.'));
        }

        if (question9.trim() === '') {
            errors.push(t('Please provide a response for Question 9.'));
        }
        if (question10.trim() === '') {
            errors.push(t('Please provide a response for Question 10.'));
        }



        setErrors(errors);
        return errors;
    };
    async function submitForm(e) {
        e.preventDefault();
        const formErrors = validateForm();

        if (formErrors.length === 0) {
            const bodyFormData = new FormData();
            const result = parseInt(question1) + parseInt(question2) + parseInt(question3) + parseInt(question4) + parseInt(question5) + parseInt(question6) + parseInt(question7) + parseInt(question8) + parseInt(question9) + parseInt(question10);

            setTotal(result);

            bodyFormData.append('Patient Name', patientName);
            bodyFormData.append('Age', age);
            bodyFormData.append('Gender', gender);
            bodyFormData.append('Timing', timing);
            if (timing === t('After Surgery')) {
                bodyFormData.append('Date', date);
            } else {
                bodyFormData.append('Date', '0');

            }
            bodyFormData.append('Pain intensity', question1);
            bodyFormData.append('Personal care (washing, dressing etc)', question2);
            bodyFormData.append('Lifting', question3);
            bodyFormData.append('Walking', question4);
            bodyFormData.append('Sitting', question5);
            bodyFormData.append('Standing', question6);
            bodyFormData.append('Sleeping', question7);
            bodyFormData.append('Mood swings', question8);
            bodyFormData.append('Social life', question9);
            bodyFormData.append('Travelling', question10);
            bodyFormData.append('Total Score', result);
            const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/Patient-Survey`, bodyFormData)
                .then(response => {

                    if (response.data.Ecode === 200) {
                        console.log(response.data);

                        if (response.data.Edata.request_id > 0) {

                            setSubmitMsg(t('Thank you for completing the survey!'));

                        } else {
                            setSubmitMsg('Error !! please Check Your Data')

                        }

                        handleShow();

                    }

                })
                .catch(error => {
                    // Handle any errors
                    console.error(error);
                });

        }
    }
    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>
        {i18n.language === 'en' ? <PageTitle title={t('Patient')} anotherTitle={t('Survey')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/Patient-questionnaire`} currentPage={t('Patient Survey')} /> : <PageTitle title={t('Survey')} anotherTitle={t('Patient')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/Patient-questionnaire`} currentPage={t('Patient Survey')} />}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='p-0'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="box-shadow col-md-8">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 text-center">
                                            <img src="/images/forms/Survey image.png" className='img-fluid' alt="open day" />
                                            <center>
                                                <div class="switch">
                                                    <input id="language-toggle" class="check-toggle check-toggle-round-flat" type="checkbox" onClick={() => {
                                                        if (i18n.language === 'en') {
                                                            handleLanguageChange('ar');
                                                            document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');

                                                            document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');
                                                            navigate(`/${Cookies.get('i18next')}/${pathname.slice(4)}`)
                                                        } else {
                                                            handleLanguageChange('en');
                                                            document.getElementsByTagName("html")[0].setAttribute("lang", 'en');

                                                            document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');

                                                            navigate(`/${Cookies.get('i18next')}/${pathname.slice(4)}`);
                                                        }
                                                    }} />
                                                    <label for="language-toggle"></label>
                                                    <span className={i18n.language === 'en' ? 'on' : 'off'}> English</span>
                                                    <span className={i18n.language === 'ar' ? 'on' : 'off'}>  العربية</span>
                                                </div>
                                            </center>
                                        </div>
                                        <div className="col-md-8 p-5">
                                            <div className='row'>

                                                <div class="form-group col-md-12">
                                                    <input id="form_name" type="text" name="name" className={`fs-6 form-control ${errors.includes(t('Please provide Your Name.')) ? 'is-invalid' : ''}`} placeholder={t('Patient Name')} value={patientName} onChange={(e) => setPatientName(e.target.value)} data-error={t("Please provide Your Name.")} />
                                                    {errors.includes(t('Please provide Your Name.')) && <p className="ivaild-error">{t('Please provide Your Name.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <input id="form_name" type="number" name="age" className={`fs-6 form-control ${errors.includes(t('Please provide Your Age.')) ? 'is-invalid' : ''}`} placeholder={t('Age')} value={age} onChange={(e) => setAge(e.target.value)} data-error={t("Please provide Your Age.")} />
                                                    {errors.includes(t('Please provide Your Age.')) && <p className="ivaild-error">{t('Please provide Your Age.')}</p>}

                                                </div>
                                                {/* <div class="form-group col-md-6">
                                                    <input id="form_name" type="date" name="date" className={`form-control ${errors.includes(t('Please provide Your Date.')) ? 'is-invalid' : ''}`} placeholder={t('Date')} value={date} onChange={(e) => setDate(e.target.value)} data-error={t("Please provide Your Date.")} />
                                                    {errors.includes(t('Please provide Your Date.')) && <p className="ivaild-error">{t('Please provide Your Date.')}</p>}

                                                </div> */}

                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label htmlFor='' className={`${errors.includes(t('Please provide Your Gender.')) ? 'is-invalid fs-6' : 'fs-6'}`}>{t('Gender')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value={t("Male")}
                                                            name='gender'
                                                            checked={gender === t('Male')}
                                                            onChange={(e) => setGender(e.target.value)}
                                                        />
                                                        {t("Male")}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            name='gender'
                                                            value={t("Female")}
                                                            checked={gender === t('Female')}
                                                            onChange={(e) => setGender(e.target.value)}
                                                        />
                                                        {t("Female")}
                                                    </label><br />

                                                    {errors.includes(t('Please provide Your Gender.')) && <p className="ivaild-error">{t('Please provide Your Gender.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label htmlFor='' className={`${errors.includes(t('Please provide Your Timing')) ? 'is-invalid fs-6' : 'fs-6'}`}>{t('Timing')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value={t("Before Surgery")}
                                                            name='Timing'
                                                            checked={timing === t('Before Surgery')}
                                                            onChange={(e) => setTiming(e.target.value)}
                                                        />
                                                        {t("Before Surgery")}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            name='Timing'
                                                            value={t("After Surgery")}
                                                            checked={timing === t('After Surgery')}
                                                            onChange={(e) => setTiming(e.target.value)}
                                                        />
                                                        {t("After Surgery")}
                                                    </label><br />

                                                    {errors.includes(t('Please provide Your Timing')) && <p className="ivaild-error">{t('Please provide Your Timing')}</p>}

                                                </div>
                                                {timing === t('After Surgery') && (<> <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label htmlFor='' className={`${errors.includes(t('Please provide The Date.')) ? 'is-invalid fs-6' : 'fs-6'}`}> {t('Date of Surgery')}</label>
                                                </div>
                                                    <div class="form-group col-md-12">
                                                        <input className='me-2 form-control'
                                                            type="date"
                                                            value={date}
                                                            name='date'
                                                            onChange={(e) => setDate(e.target.value)}
                                                        />
                                                        {errors.includes(t('Please provide The Date.')) && <p className="ivaild-error">{t('Please provide The Date.')}</p>}

                                                    </div>
                                                </>)}
                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label htmlFor='' className={`${errors.includes(t('Please provide a response for Question 1.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Pain intensity')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question1"
                                                            checked={question1 === '0'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('I have no pain at the moment')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question1"
                                                            checked={question1 === '1'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('The pain is very mild at the moment')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question1"
                                                            checked={question1 === '2'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('The pain is moderate at the moment')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question1"
                                                            checked={question1 === '3'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('The pain is fairly severe at the moment')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question1"
                                                            checked={question1 === '4'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('The pain is very severe at the moment')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question1"
                                                            checked={question1 === '5'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        {t('The pain is the worst imaginable at the moment')}
                                                    </label><br />

                                                    {errors.includes(t('Please provide a response for Question 1.')) && <p className="ivaild-error">{t('Please provide a response for Question 1.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 2.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Personal care (washing, dressing etc)')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question2"
                                                            checked={question2 === '0'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        {t('I can look after myself normally without causing extra pain')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question2"
                                                            checked={question2 === '1'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        {t('I can look after myself normally but it causes extra pain')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question2"
                                                            checked={question2 === '2'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        {t('It is painful to look after myself and I am slow and careful')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question2"
                                                            checked={question2 === '3'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        {t('I need some help but manage most of my personal care')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question2"
                                                            checked={question2 === '4'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        {t('I need help every day in most aspects of self-care')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question2"
                                                            checked={question2 === '5'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        {t('I do not get dressed, I wash with difficulty and stay in bed')}
                                                    </label><br />

                                                    {errors.includes(t('Please provide a response for Question 2.')) && <p className="ivaild-error">{t('Please provide a response for Question 2.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 3.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Lifting')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question3"
                                                            checked={question3 === '0'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('I can lift heavy weights without extra pain')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question3"
                                                            checked={question3 === '1'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('I can lift heavy weights but it gives extra pain')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question3"
                                                            checked={question3 === '2'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from lifting heavy weights off the floor, but I can manage if they are conveniently placed eg. on a table')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question3"
                                                            checked={question3 === '3'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from lifting heavy weights,but I can manage light to medium weights if they are conveniently positioned')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question3"
                                                            checked={question3 === '4'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('I can lift very light weights')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question3"
                                                            checked={question3 === '5'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        {t('I cannot lift or carry anything at all')}
                                                    </label><br />


                                                    {errors.includes(t('Please provide a response for Question 3.')) && <p className="ivaild-error">{t('Please provide a response for Question 3.')}</p>}


                                                </div>

                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 4.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Walking')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question4"
                                                            checked={question4 === '0'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Pain does not prevent me walking any distance')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question4"
                                                            checked={question4 === '1'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from walking more than 2 kilometres')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question4"
                                                            checked={question4 === '2'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from walking more than 1 kilometres')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question4"
                                                            checked={question4 === '3'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from walking more than 500 metres')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question4"
                                                            checked={question4 === '4'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('I can only walk using a stick or crutches')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question4"
                                                            checked={question4 === '5'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        {t('I am in bed most of the time')}
                                                    </label><br />


                                                    {errors.includes(t('Please provide a response for Question 4.')) && <p className="ivaild-error">{t('Please provide a response for Question 4.')}</p>}

                                                </div>

                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 5.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Sitting')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question5"
                                                            checked={question5 === '0'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('I can sit in any chair as long as I like')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question5"
                                                            checked={question5 === '1'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('I can only sit in my favourite chair as long as I like')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question5"
                                                            checked={question5 === '2'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('Pain prevents me sitting more than one hour')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question5"
                                                            checked={question5 === '3'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t("Pain prevents me from sitting more than 30 minutes")}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question5"
                                                            checked={question5 === '4'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from sitting more than 10 minutes')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question5"
                                                            checked={question5 === '5'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from sitting at all')}
                                                    </label><br />

                                                    {errors.includes(t('Please provide a response for Question 5.')) && <p className="ivaild-error"> {t('Please provide a response for Question 5.')}</p>}

                                                </div>

                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 6.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Standing')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question6"
                                                            checked={question6 === '0'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('I can stand as long as I want without extra pain')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question6"
                                                            checked={question6 === '1'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('I can stand as long as I want but it gives me extra pain')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question6"
                                                            checked={question6 === '2'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from standing for more than 1 hour')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question6"
                                                            checked={question6 === '3'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from standing for more than 3 minutes')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question6"
                                                            checked={question6 === '4'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from standing for more than 10 minutes')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question6"
                                                            checked={question6 === '5'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from standing at all')}
                                                    </label><br />
                                                    {errors.includes(t('Please provide a response for Question 6.')) && <p className="ivaild-error">{t('Please provide a response for Question 6.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 7.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Sleeping')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question7"
                                                            checked={question7 === '0'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('My sleep is never disturbed by pain')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question7"
                                                            checked={question7 === '1'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('My sleep is occasionally disturbed by pain')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question7"
                                                            checked={question7 === '2'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Because of pain I have less than 6 hours sleep')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question7"
                                                            checked={question7 === '3'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Because of pain I have less than 4 hours sleep')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question7"
                                                            checked={question7 === '4'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Because of pain I have less than 2 hours sleep')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question7"
                                                            checked={question7 === '5'}
                                                            onChange={(e) => setQuestion7(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from sleeping at all')}
                                                    </label><br />


                                                    {errors.includes(t('Please provide a response for Question 7.')) && <p className="ivaild-error">{t('Please provide a response for Question 7.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 8.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Mood swings')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question8"
                                                            checked={question8 === '0'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t("The pain doesn't affect my mood")}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question8"
                                                            checked={question8 === '1'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t("Sometimes pain affects my mood but doesn't disturb my life")}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question8"
                                                            checked={question8 === '2'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('The pain makes me feel stressed or anxious frequently')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question8"
                                                            checked={question8 === '3'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('The pain makes me feel nervous and angry most of the time')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question8"
                                                            checked={question8 === '4'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('The pain severely affects my mood and causes me depression')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question8"
                                                            checked={question8 === '5'}
                                                            onChange={(e) => setQuestion8(e.target.value)}
                                                        />
                                                        {t('The pain makes me in a constant state of depression')}
                                                    </label><br />



                                                    {errors.includes(t('Please provide a response for Question 8.')) && <p className="ivaild-error">{t('Please provide a response for Question 8.')}</p>}


                                                </div>

                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 9.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Social life')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question9"
                                                            checked={question9 === '0'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('My social life is normal and gives me no extra pain')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question9"
                                                            checked={question9 === '1'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('My social life is normal but increases the degree of pain')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question9"
                                                            checked={question9 === '2'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('Pain has no significant effect on my social life apart from limiting my more energetic interests eg, sport')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question9"
                                                            checked={question9 === '3'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('Pain has restricted my social life and I do not go out as often')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question9"
                                                            checked={question9 === '4'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('Pain has restricted my social life to my home')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question9"
                                                            checked={question9 === '5'}
                                                            onChange={(e) => setQuestion9(e.target.value)}
                                                        />
                                                        {t('I have no social life because of pain')}
                                                    </label><br />


                                                    {errors.includes(t('Please provide a response for Question 9.')) && <p className="ivaild-error">{t('Please provide a response for Question 9.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12 d-flex align-items-center justify-content-start">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 10.')) ? 'is-invalid fs-5 fw-bold text-theme' : 'fs-5 fw-bold text-theme'}`}>{t('Travelling')}</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="0"
                                                            name="question10"
                                                            checked={question10 === '0'}
                                                            onChange={(e) => setQuestion10(e.target.value)}
                                                        />
                                                        {t('I can travel anywhere without pain')}
                                                    </label><br />

                                                    <label className='form-check-label radio__label'>
                                                        <input
                                                            className='me-2'
                                                            type="radio"
                                                            value="1"
                                                            name="question10"
                                                            checked={question10 === '1'}
                                                            onChange={(e) => setQuestion10(e.target.value)}
                                                        />
                                                        {t('I can travel anywhere but it gives me extra pain')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="2"
                                                            name="question10"
                                                            checked={question10 === '2'}
                                                            onChange={(e) => setQuestion10(e.target.value)}
                                                        />
                                                        {t('Pain is bad but I manage journeys over two hours')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="3"
                                                            name="question10"
                                                            checked={question10 === '3'}
                                                            onChange={(e) => setQuestion10(e.target.value)}
                                                        />
                                                        {t('Pain restricts me to journeys of less than one hour')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="4"
                                                            name="question10"
                                                            checked={question10 === '4'}
                                                            onChange={(e) => setQuestion10(e.target.value)}
                                                        />
                                                        {t('Pain restricts me to short necessary journeys under 30 minutes')}
                                                    </label><br />
                                                    <label className='form-check-label radio__label'>
                                                        <input className='me-2'
                                                            type="radio"
                                                            value="5"
                                                            name="question10"
                                                            checked={question10 === '5'}
                                                            onChange={(e) => setQuestion10(e.target.value)}
                                                        />
                                                        {t('Pain prevents me from travelling except to receive treatment')}
                                                    </label><br />



                                                    {errors.includes(t('Please provide a response for Question 10.')) && <p className="ivaild-error">{t('Please provide a response for Question 10.')}</p>}

                                                </div>
                                                <div className="col-md-6 text-center">
                                                    <button className="btn btn-border btn-circle w-100" type='submit' ><span>{t('Submit')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>{t('Patient Survey')}</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>

                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        {t('Close')}
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
